.about-us {
  padding: 0 50px 70px;

  z-index: 1;
  position: relative;

  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-image: url("../images/block1.jpg");
  background-repeat: no-repeat;
  @include shadow-block;

  &__strip {
    margin-top: 50px;
    margin-bottom: 67px;

    @include strip;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 47px;

    @include about-us-title;

    @media (max-width: 600px) {
      margin: 47px;
    }
  }

  &__p {
    width: 950px;
    margin-bottom: 55px;

    @include about-us-p;
    text-align: left;
    white-space: normal;
    color: #e5e5e5;
  }

  &__read-more {
    width: 270px;
    height: 75px;

    font-family: "Gill Sans", sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: lighter;
    text-transform: uppercase;

    text-align: center;
    line-height: 75px;
    color: #e5e5e5;

    border: solid 2px #064d66;

    transition: background-color 0.3s linear;

    &:hover {
      background-color: #064d66;
    }
  }
}

@media (max-width: 1250px) {
  .about-us {

    &__p {
      width: 750px;
    }
  }
}

@media (max-width: 800px) {
  .about-us {

    &__p {
      width: 550px;
    }
  }
}

@media (max-width: 600px) {
  .about-us {

    &__p {
      width: 400px;
    }
  }
}

@media (max-width: 450px) {
  .about-us {

    &__title {
      margin-bottom: 47px;

      font-size: 35px;
    }

    &__p {
      width: 320px;

      font-size: 22px;
    }
  }
}
