@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CODE Light";
  src: url("../fonts/CODE Light.otf") format("otf");
  font-weight: lighter;
  font-style: normal;
}
