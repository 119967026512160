.header {
  height: 100vh;

  &__img {
    display: none;
  }

  &__top {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    background-color: rgba($color: #111010, $alpha: 0.87);
    backdrop-filter: blur(4px);

    box-shadow: 5px 10px 10px 1px rgba($color: #111010, $alpha: 0.3);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 1440px;
    width: 100%;
    margin: auto;

    height: 80px;
    padding: 0 150px;
  }

  &__logo {
    display: block;
    text-align: center;

    font-family: "Code Light", sans-serif;
    font-size: 24px;
    font-weight: 900;

    color: #096c91;

    -webkit-user-select: none;
  }

  &__bg {
    z-index: -1;
    position: relative;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: top;

    // filter: brightness(0.7);
  }
}

@media (max-width: 1250px) {
  .header {

    &__container {
      padding: 0 100px;
    }
  }
}

@media (max-width: 1000px) {
  .header {
    height: 100vw;

    &__container {
      justify-content: center;
      padding: 0 80px;
    }
  }
}

@media (max-width: 700px) {
  .header {
    height: 100vw;
    
    &__container {
      padding: 0 80px;
    }

    &__bg {
      height: 100%;
    }
  }
}
