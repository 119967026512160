@import "vars";

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: $body-bg-color;
}

body,
div,
span,
label,
h1,
h2,
h3,
h4,
button,
a,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

li {
  list-style: none;
}
