.nav {
  -webkit-user-select: none;

  &__items {
    display: grid;
    grid-template-columns: repeat(6, max-content);

    @include nav-text;
  }

  &__link {
    color: #c2c2c2;
  }

  &__list {
    padding: 30px 20px;

    transition: 0.35s linear;

    &:hover {
      background-color: rgba($color: $c-gray, $alpha: 0.87);
      color: #0b6282;
    }
  }
}

@media (max-width: 950px) {
  .nav {
    display: none;
  }
}
