$testimonial-img-size: 100px;

.testimonials {
  min-height: 813px;
  height: max-content;

  background-size: cover;
  background-image: url("../images/block1.jpg");
  background-repeat: no-repeat;
  @include shadow-block;
}

.title-block {
  padding-top: 85px;
  margin-bottom: 135px;
  width: 100%;

  display: flex;
  justify-content: center;

  &__title {
    margin: 0 35px;
    @include offers-title;
    align-self: center;
  }

  &__rectangle1 {
    @include strip2-services;

    align-self: center;

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.carousel{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 30%;
  }

  &__container {
    padding: 0 80px;

    @media (max-width: 600px) {
      padding: 0 30px;
    }
  }
}

#customers-testimonials {
  .item-details{
    background-color: #333333;
    color: #fff;
    padding: 20px 10px;
    text-align: left;

    h5{
      margin: 0 0 15px;
      font-size: 18px;
      line-height: 18px;

      span{
        color: red;
        float:right;
        padding-right: 20px;
      }
    }
    p{
      font-size: 14px;
    }
  }
  .item {
    height: 350px;
    text-align: center;
    // padding: 20px;
    margin-bottom: 80px;
  }
}

.owl-carousel .owl-nav [class*='owl-'] {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.owl-carousel .owl-nav [class*='owl-'].disabled:hover {
  background-color: #D6D6D6 !important;
}

.owl-carousel .owl-nav button > span {
  padding-bottom: 15px;
  font-size: 50px !important;

  @media (max-width: 600px) {
    font-size: 30px !important;
    padding-bottom: 8px;
  }
}

.owl-carousel {
  position: relative !important;
}

.owl-carousel .owl-next,
.owl-carousel .owl-prev {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 30% !important;
  font-size: 20px !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
  text-align: center !important;
  background: none !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  @media (max-width: 600px) {
    font-size: 16px !important;
    width: 30px !important;
    height: 30px !important;
  }
}

.owl-carousel .owl-prev {
  left: -70px !important;

  @media (max-width: 600px) {
    left: -30px !important;
  }
}

.owl-carousel .owl-next {
  right: -70px !important;

  @media (max-width: 600px) {
    right: -30px !important;
  }
}

.owl-dots {
  opacity: 0 !important;
}

#testimonial {
  &__img {
    width: $testimonial-img-size;
    height: $testimonial-img-size;
  }
}

.testimonial {
  display: flex;

  &__text {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #4e4e4e;
  }

  &__name {
    padding-top: 7px;
    padding-left: 20px;

    color: $text-color;
    text-align: left;
  }

  &__from {
    color: $text-color;
    padding-left: 20px;

    text-align: left;
  }
}

.items {

  &__text {
    margin-top: -50px;
    margin-left: 30px;
    padding: 30px;
    width: 90%;
    background-color: #333;
    text-align: center;
  }

  &__quotes {
    text-align: center;
    color: white;
    font-family: 'Secular One', sans-serif;
    font-size: 50px;
  }

  &__paragraph {
    height: max-content;

    text-align: center;
    color: white;

    font-size: 16px;
    font-style: italic;
  }

  &__quotes:first-child {
    padding-top: 50px;
  }

  &__quotes:last-child {
    padding-top: 20px;
  }
}
